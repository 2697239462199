.block-support {
  /* Placeholder: */
  background: $white;
  border: 1px solid rgba(171,171,171,0.32);
  box-shadow: 0 8px 38px 0 rgba(0,0,0,0.10);
  border-radius: $border-radius;



  border: 1px solid #E9F0F3;


  h3,
  p {
    display:block;
   clear:both;
  }

.support-text {

    border-top: 1px solid #E9F0F3;
    border-bottom: 1px solid #E9F0F3;
    background-color: #FAFBFC;
}
  .support-img {
    margin-top:-32px;
  }


}

@include media-breakpoint-down(sm) {
  .block-support {
    background: none;
    border-bottom: none;

    h3 {
      font-size: 1.4em;
    }

    p.lead {
      font-size: 1rem;
    }

    .support-text {
    }
  }
}
