.navbar {


  .active a,
 {
     color:$brand-primary;
     @extend .t-bold;
 }

  a {
    // font-size: 1rem; //16px
    color:$gray;
    text-decoration: none;


    &:hover,
    &.hover {
      color:$brand-primary;

    }

    &:focus,
    &.focus {
    color:$brand-primary;
    }

    &:active,
    &.active {
      color:$brand-primary;

  }

  a .btn-cta,
  a.btn-cta {
    color:$white;
  }
}
}
