
// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Colors
$theme-colors: (
  "primary": #084785,
  "danger": #ff4136
);

$brand-primary:                   #084785;
$gray:                            #6D6E6D;
$white :                          #ffffff;
$btn-primary-bg-color:            #E3692A;
$btn-primary-dark-bg-color:       #E5550A;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                rgba(0,0,0,0.60);

// Links
//
// Style anchor elements.

$link-color:                theme-color("primary") !default;
$link-decoration:           none !default;
// $link-hover-color:          darken($link-color, 15%) !default;
// $link-hover-decoration:     underline !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1.5rem !default;

// text
$text-color:#6D6E6D;
$letter-spacing-base: -.02em;


// Spacing
$spacer:            20px;
$spacer-x:          $spacer;
$spacer-y:          $spacer;
$grid-gutter-width: 20px;


// Typography
$font-size-root: 1rem;
$font-size-base: 1.05rem;
$font-family-sans-serif:  'Titillium Web', sans-serif;
$font-family-serif:       "Lora", Georgia, "Times New Roman", Times, serif;
$font-family-base:        $font-family-sans-serif;
$font-weight-base:        400;
$font-weight-bold:        600;
$line-height-base:        1.6;


// For h1-h6
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 600;
$headings-line-height: 1.2;
$headings-small-color: inherit;
$headings-color:  #6D6E6D;

$font-size-h1: 1.625rem;
$font-size-h2: 1.39rem !default;
$font-size-h3: 1.375rem !default;
$font-size-h4: 1.125rem !default;
$font-size-h5: 1.1rem !default;
$font-size-h6: 1rem !default;


// For .lead
$lead-font-size:      ($font-size-base * 1.15);
$lead-font-size-sm:   ($font-size-base * 1.25);
$lead-font-weight:    300;
$lead-line-height:    1.6;
$lead-letter-spacing: 0;



// Buttons
$btn-font-size:      1.2em;
$btn-font-weight:    600;
$btn-text-transform: none;
$btn-letter-spacing: normal;
$btn-padding-x-xs:   0.063rem !default; // TODO: Change this to REMs since most of v4 in REMS
$btn-padding-y-xs:   0.313rem !default; // TODO: Change this to REMs since most of v4 in REMS


// $btn-primary-bg:     $green;
// $btn-primary-border: $green-dark;
$link-color:         $brand-primary;
$link-hover-color:   #000;

$btn-border-radius: 6px;


$border-radius: 14px !default;


// Navbar

$navbar-light-color:                #4A4A4A;
$navbar-light-hover-color:          $brand-primary;
$navbar-light-active-color:         $brand-primary;
$navbar-light-disabled-color:       rgba(#4A4A4A, .3) !default;
