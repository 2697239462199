/*

Product detail page

*/
.woocommerce #content div.product div.images,
.woocommerce div.product div.images,
.woocommerce-page #content div.product div.images,
.woocommerce-page div.product div.images {
  width:80%;
}

.high-volume-order {

  border-top:1px solid #f7f7f7;
  border-bottom:1px solid #f7f7f7;

  a {
    color: $body-color;
  }
}


.product-card {



      border: 0;
border-radius: 0.25rem;
display: inline-block;
position: relative;
width: 100%;
margin-bottom: 30px;


.btn {
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

}
.related-products,
.block-products {



}


.block-product-info {
  border-top:1px solid #dbd2c8;
  border-bottom:1px solid #dbd2c8;
}



.hp-block {

  .hp-block-bg {
  //  box-shadow: rgba(0, 0, 0, 0.56) 0px 16px 38px -12px, rgba(0, 0, 0, 0.12) 0px 4px 25px 0px, rgba(0, 0, 0, 0.2) 0px 8px 10px -5px;
  }

.white {
  color:#fff;

}

  h4 {

    font-size: 24px;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 600;

    text-transform: uppercase;

  }


  .inside {
    min-height:174px;
  }



}

.block-inverse {
  h2 {
    color:#fff;

    text-transform: uppercase;

  }

  .lead {
    color:#fff;

    font-weight:300;
  }
}

.nutricional-block table {
  font-size: .8em;
}

.badge-sale {
  position: absolute;
  top: -.5em;
left: -.5em;
}




.woocommerce .quantity .qty {
    width: 4.2em;
    text-align: center;
    padding: .5rem;
}


.woocommerce-checkout {

  h3 {
  font-size: 1.2em;
}

label {

    margin-bottom: .1rem;
}

}


span.label-title {

  color:#000;
  text-transform: uppercase;

}
