.header-shadow {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10);
}

.top-nav {


  li {

    @extend .nav-item;

  }

  ul > li > a {
    @extend .nav-link;

    padding: 0rem 1rem;

    font-size:.98rem;
    //color: #73777A;
    color: #333333;

    &:hover {
      color:#02367f;
    }
  }

  .phone {
    font-weight:$font-weight-bold;
    color: #3B3B3B;
    float:right;

    i {
      color: $brand-primary;
    }

    a {
      color: #3B3B3B;
    }
  }

}



.btn-cart-container {
  position: relative;
  z-index:1000000;
}
.btn-cart {

  background:#fff;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 16px 38px -12px, rgba(0, 0, 0, 0.12) 0px 4px 25px 0px, rgba(0, 0, 0, 0.2) 0px 8px 10px -5px;

.badge-success{
  background:#61bf23;
}
.badge-cart-amount{
  position: absolute;
  float:right;
  top: .2em;
right: -1.6em;
}
}
