.customerservice {

  a {

    color:#333333;


    &:hover,
    &.hover {
      color:$brand-primary;
    }

    &:focus,
    &.focus {
      color:$brand-primary;
    }

    &:active,
    &.active {
    color:$brand-primary;
    }

  }
}

@include media-breakpoint-down(sm) {
  .block-support {
    background: none;
    border-bottom: none;

    h3 {
      font-size: 1.4em;
    }

    p.lead {
      font-size: 1rem;
    }

    .support-text {
    }
  }
}
