/* Buttons */

.btn-cta {
  color:$white;
  background:$btn-primary-dark-bg-color;

  a {
      color:$white;
  }
}


.btn-primary {

  background:$btn-primary-bg-color;
  border-color:$btn-primary-bg-color;
  border-bottom: 3px solid $btn-primary-dark-bg-color;
  color:$white;

  &:hover,
  &.hover {
    background:$btn-primary-dark-bg-color;
    border:1px solid $btn-primary-dark-bg-color;
    border-bottom: 3px solid $btn-primary-bg-color;
  }

  &:focus,
  &.focus {
    background:$btn-primary-dark-bg-color;
    border:1px solid $btn-primary-dark-bg-color;
    box-shadow: none;
      border-bottom: 3px solid $btn-primary-bg-color;
  }

  &:active,
  &.active {
    background:$btn-primary-dark-bg-color;
    border:1px solid $btn-primary-dark-bg-color;
    box-shadow: none;
      border-bottom: 3px solid $btn-primary-bg-color;
  }
}


.btn-default {

  background:transparent;
  border-color: $btn-primary-dark-bg-color;
  color:#4A4A4A;


  &:hover,
  &.hover {
    background:$btn-primary-dark-bg-color;
    border-color: $btn-primary-dark-bg-color;
    color:#fff;
  }

  &:focus,
  &.focus {
    background:$btn-primary-dark-bg-color;
    border-color: $btn-primary-dark-bg-color;
    color:#fff;
  }

  &:active,
  &.active {
    background:$btn-primary-dark-bg-color;
    border-color: $btn-primary-dark-bg-color;
    color:#fff;
  }
}

.btn-white {

    background: #FFFFFF;
    border: 1px solid #417505;
    border-bottom: 3px solid #418F15;
    color:#417505;



  &:hover,
  &.hover {
    background: #417505;
    border: 1px solid #418F15;
    border-bottom: 3px solid #418F15;
    color:#ffffff;
  }

  &:focus,
  &.focus {
    background: #417505;
    border: 1px solid #418F15;
    border-bottom: 3px solid #418F15;
    color:#ffffff;
  }

  &:active,
  &.active {
    background: #417505;
    border: 1px solid #418F15;
    border-bottom: 3px solid #418F15;
    color:#ffffff;
  }
}
