.card-raised {
      box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
}


.card-pricing {
    text-align: center;

    h1 small {
    font-size: 1.2em;
    display: inline-flex;
    height: 0;
    }

    h1 small:first-child {
        position: relative;
        top: -17px;
        font-size: .4em;
    }

h1 small:last-child {
  font-size: .4em;
  color: #777;
}

}

.card-plain {
    background: transparent;
    box-shadow: none;
    border:none;
}
