// background Colors

.bg-white {
  background:$white;
}

.bg-orange {
  background:$brand-primary;
}

.bg-green-transparent {
  background: rgba(118,191,76,0.42);
}

.bg-light-gray {
  background: #F7F9F9;
}

.bg-transparent {
  background:transparent !important;
}



// Font values

.t-orange {
  color:$brand-primary;
}

.t-white {
  color:$white;
}

.t-gray {
  color:$gray;
}

.t-small {
  font-size: .8em;
}

.t-shadow {
  text-shadow: 0 2px 4px rgba(0,0,0,0.50);
}

.t-normal {
  font-weight: 400;
}

.t-italic {
  font-style: italic;
}

.t-bold {
  font-weight: 600;
}

.t-light {
  font-weight: 300;
}

.t-mute {
  color:#3B3B3B;
}
