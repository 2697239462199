

.col-shadow {
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.20);
}

.img-shadow-right{
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.20);
}

.img-shadow-left {
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.20);
}

.video-shadow {
  box-shadow: 0 10px 50px 0 rgba(0,0,0,0.20);
}

.green {
      color: #319515
}
