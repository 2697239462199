
// Employees row 1
.row-1-s { margin-top: -100px}
.row-1-m { margin-top: 60px}
.row-1-l { margin-top: 180px}

// Employees row 2
.row-2-s { margin-top: -360px}
.row-2-m { margin-top: -380px}
.row-2-l { margin-top: -418px}
