// Block content steps


.block-content-steps {

  .steps-line:before {
    content: '';
    display: block;
    position: absolute;
    left: 32px;
    top: 0;
    height: 100%;
    border-left: 2px solid #ebedee;
  }

  .number {
    position: absolute;
    left: 12px;
    margin-top: 0px;
    width: 40px;
    height: 40px;

    overflow: hidden;
    background: #fff;
    border:2px solid $brand-primary;
    text-align: center;
    color: $brand-primary;
    line-height: 36px;
    font-size:1em;


  }

  h3 {
    padding-top: 6px;
  }

}
